import { useNavigate } from "react-router-dom";
import { Button } from "./CopiedFromRefactor/Button";
import { usePage } from "../contexts/PageProvider";
import CircleLoader from "../../../components/Loaders/CircleLoader/CircleLoader";
import {
  iconCloseDialog,
  iconLeftArrow,
  iconPreview,
  iconPreviewDisabled,
  iconPreviewHovered,
  iconRightArrow,
  iconRightArrowHovered,
} from "../icons";
import { getSection } from "../hooks/getSection";
import { SectionName } from "../config";
import { setNewOptions } from "../hooks/saveNewOptions";
import { useEffect, useState } from "react";
import { useCallbackPrompt } from "../hooks/useCallbackPrompt";
import { Dialog } from "@mui/material";
import { WarningActions } from "../../../components/dialogs/AppDialog/components/WarningActions";

export function FormNavigation(props) {
  const navigate = useNavigate();
  const {
    isLoading,
    options,
    setLoading,
    section,
    marketProblemsAndSolutions,
    marketCriticalSuccessFactor,
  } = usePage();
  const {
    onNext,
    onGenerate,
    doesntHaveGenerateBtn,
    doesntHaveNextSectionBtn,
    disableGenerateBtn,
    disableBtns,
    onSave,
    hasNoPreviousSection,
    initialState,
    newState,
  } = props;

  const bpScenarioId = global.Modeliks.BusinessPlanScenarioInfo.ID;

  const [initialValues, setInitialValues] = useState(null);
  const [initialOptions, setInitialOptions] = useState(null);
  const [shouldSavePopupOpen, setShouldSavePopupOpen] = useState(true);

  const [showPrompt, confirmNavigation, cancelNavigation, setShowPrompt] = useCallbackPrompt(
    shouldSave() && shouldSavePopupOpen,
  );

  function shouldSave() {
    return (
      (initialValues || newState) && JSON.stringify(initialValues) !== JSON.stringify(newState)
    );
  }

  useEffect(() => {
    if (!initialValues && initialState) {
      setInitialValues(initialState);
    }
    if (!initialOptions && options) {
      setInitialOptions(options);
    }
  }, [initialState, options]);

  const handleGenerateClick = async () => {
    if (onGenerate && !isLoading) {
      setShouldSavePopupOpen(false);
      const shouldGenerate = await onGenerate();

      if (shouldGenerate) {
        const section = await getSection(shouldGenerate.sectionName, bpScenarioId);
        const { problemsAndSolutions, ...optionsWithoutProblems } = options || {};

        if (shouldGenerate.sectionName != SectionName.MarketSolutions)
          if (options) await setNewOptions(bpScenarioId, optionsWithoutProblems);
        if (section && section.PageID) {
          navigate("/business_plan", { state: { sectionId: section.PageID } });
        } else {
          console.error("Failed to retrieve section or PageId is missing.");
        }
      }
    }
  };

  async function saveInputs() {
    setShowPrompt(false);
    await onNext();
    confirmNavigation();
  }

  const newValuesAreSet = JSON.stringify(initialValues) !== JSON.stringify(newState);
  const newOptionsAreSet = options && JSON.stringify(initialOptions) !== JSON.stringify(options);

  const handeOnNextClick = async () => {
    if (isLoading) return;
    setShouldSavePopupOpen(false);
    setLoading(true);

    if (newValuesAreSet) await onNext();

    if (newOptionsAreSet) {
      const { problemsAndSolutions, ...optionsWithoutProblems } = options || {};

      await setNewOptions(bpScenarioId, optionsWithoutProblems);
    }

    // SKIP SOLUTIONS IF NO PROBLEMS SELECTED ON NEXT SECIONT
    if (section.nextSectionUrl === "market_analysis/solutions") {
      if (!newState.problemsAndSolutions) {
        navigate(`/business_plan/ai_generator/market_analysis/target_market`);
        return;
      }
    }

    // SKIP CRICTIAL SUCCESS FACTORS IF NO COMPETITORS SELECTED ON NEXT SECIONT
    if (section.nextSectionUrl === "market_analysis/critical_success_factors") {
      if (!newState.competitors) {
        navigate(`/business_plan/ai_generator/market_analysis/unique_selling_proposition`);
        return;
      }
    }

    navigate(`/business_plan/ai_generator/${section.nextSectionUrl}`);
  };

  function handlePreviousSection() {
    if (isLoading) return;

    if (section.previousSectionUrl === "market_analysis/critical_success_factors") {
      if (
        marketCriticalSuccessFactor.criticalSuccessFactors === null ||
        marketCriticalSuccessFactor.successFactorCompetitors === null
      ) {
        navigate(`/business_plan/ai_generator/market_analysis/competitors`);
      } else {
        navigate(`/business_plan/ai_generator/${section.previousSectionUrl}`);
      }
    } else if (section.previousSectionUrl === "market_analysis/solutions") {
      if (marketProblemsAndSolutions.problemsAndSolutions === null) {
        navigate(`/business_plan/ai_generator/market_analysis/problems`);
      } else {
        navigate(`/business_plan/ai_generator/${section.previousSectionUrl}`);
      }
    } else {
      navigate(`/business_plan/ai_generator/${section.previousSectionUrl}`);
    }
  }

  return (
    <div className="w-screen absolute bottom-0 left-0 py-3 px-10 bg-red-white flex justify-between gap-6 border-t-2 border-grey-light">
      {showPrompt && (
        <Dialog open={true} sx={{ ".MuiDialogContent-root": { padding: "20px 48px" } }}>
          <div className="absolute right-4 top-6 cursor-pointer" onClick={() => cancelNavigation()}>
            {iconCloseDialog}
          </div>
          {
            <WarningActions
              message={"Do you want save your changes before leaving?"}
              onCancel={() => confirmNavigation()}
              onAccept={() => saveInputs()}
            />
          }
        </Dialog>
      )}

      {hasNoPreviousSection || isLoading ? (
        <div className="w-41"></div>
      ) : (
        <Button
          onClick={() => handlePreviousSection()}
          className="overflow-hidden text-primary font-bold"
          size="large"
          rounded
          variant="tertiary"
          leftslot={iconLeftArrow}
        >
          Previous Section
        </Button>
      )}
      <div className="flex justify-center gap-11">
        {/*TODO: fix hover icon colors after merge on refactor_main*/}
        {!doesntHaveGenerateBtn ? (
          <Button
            disabled={disableGenerateBtn || disableBtns}
            outline={!disableGenerateBtn && !disableBtns}
            rounded
            onClick={handleGenerateClick}
            className="font-bold min-w-60 group"
          >
            {isLoading ? (
              <CircleLoader className={"h-6"} />
            ) : (
              <div className="flex items-center gap-2">
                <div
                  className={`block group-hover:hidden ${disableGenerateBtn || disableBtns ? "!hidden" : ""}`}
                >
                  {iconPreview}
                </div>
                <div
                  className={`hidden group-hover:block ${disableGenerateBtn || disableBtns ? "!block" : ""}`}
                >
                  {iconPreviewHovered}
                </div>
                Generate & Review Section
              </div>
            )}
          </Button>
        ) : (
          <div className="w-60"></div>
        )}
        {!doesntHaveNextSectionBtn ? (
          <Button
            rounded
            onClick={handeOnNextClick}
            disabled={disableBtns}
            className="font-bold min-w-52 group"
          >
            {isLoading ? (
              <CircleLoader className={"h-6"} />
            ) : (
              <div className="flex items-center gap-2 h-6">
                Next Section <div className="block group-hover:hidden">{iconRightArrow}</div>
                <div className="hidden group-hover:block">{iconRightArrowHovered}</div>
              </div>
            )}
          </Button>
        ) : (
          <div className="w-52"></div>
        )}
      </div>

      <div className="w-41"></div>
    </div>
  );
}
