import { useEffect, useState } from "react";
import { QuestionWrapper } from "../../components/QuestionWrapper";
import { CardSelect } from "../../components/CardSelect";
import { Button } from "../../components/CopiedFromRefactor/Button";
import { Input } from "../../components/Input";
import { usePage } from "../../contexts/PageProvider";
import { businessProductsAndServicesObjectToAiGeneratedContentDto } from "../../models/aiGeneratedContent/transformer";
import { SectionMode } from "../../helpers/enums";
import { useSave } from "../../hooks/useSave";
import { fetchOptions, saveOptions } from "../../Services/api/Repositories/BusinessPlanGenerator";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import { FormNavigation } from "../../components/FormNavigation";
import { PromptWrapper } from "../../components/PromptWrapper";

export function BusinessProductsAndServices() {
  const {
    section,
    businessProductsAndServices,
    setBusinessProductsAndServices,
    getGeneratedData,
    setLoading,
    isLoading,
    options,
    setOptions,
  } = usePage();

  const [sectionName, setSectionName] = useState({ sectionName: "" });
  const { nextSection, generateSection, saveSectionInputs } = useSave(sectionName);

  const [productCards, setProductCards] = useState([]);
  const [serviceCards, setServiceCards] = useState([]);
  const [productInputValue, setProductInputValue] = useState("");
  const [serviceInputValue, setServiceInputValue] = useState("");
  const [inputError, setInputError] = useState(false);
  const [isProductInputActive, setIsProductInputActive] = useState(false);
  const [isServiceInputActive, setIsServiceInputActive] = useState(false);
  const [loadingServices, setLoadingServices] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [productsError, setProductsError] = useState(false);
  const [servicesError, setServicessError] = useState(false);
  const [initialRender, setInitialRender] = useState(true);

  const [newProductsCardOptions, setNewProductsCardOptions] = useState([]);
  const [newServiceCardOptions, setNewServiceCardOptions] = useState([]);

  useEffect(() => {
    if (options)
      setOptions({
        ...options,
        services: [
          ...options.services,
          ...newServiceCardOptions.filter((card) => !options.services.includes(card)),
        ],
        products: [
          ...options.products,
          ...newProductsCardOptions.filter((card) => !options.products.includes(card)),
        ],
      });
  }, [newProductsCardOptions, newServiceCardOptions]);

  useEffect(() => {
    if (section) {
      setSectionName({ sectionName: section.name });
    }
  }, [section]);

  const bpScenarioId = global.Modeliks.BusinessPlanScenarioInfo.ID;

  function transformToSelectable(items = [], selectedItems = []) {
    const allItems = items.map((item) => ({
      title: item,
      isSelected: selectedItems.includes(item),
    }));

    selectedItems.forEach((selectedItem) => {
      if (!items.includes(selectedItem)) {
        allItems.push({ title: selectedItem, isSelected: true });
      }
    });

    return allItems.sort((a, b) => !!b.isSelected - (!!a.isSelected === true));
  }

  async function fetchProducts() {
    try {
      setLoadingProducts(true);
      const productResponse = await fetchOptions({
        bpScenarioId,
        optionKey: "products",
      });

      setOptions((prevOptions) => ({
        ...prevOptions,
        products: productResponse.SectionData.Options.products,
      }));
      setProductCards(transformToSelectable(productResponse.SectionData.Options.products));
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoadingProducts(false);
    }
  }

  async function fetchServices() {
    try {
      setLoadingServices(true);
      const serviceResponse = await fetchOptions({
        bpScenarioId,
        optionKey: "services",
      });

      setOptions((prevOptions) => ({
        ...prevOptions,
        services: serviceResponse.SectionData.Options.services,
      }));
      setServiceCards(transformToSelectable(serviceResponse.SectionData.Options.services));
    } catch (error) {
      console.error("Error fetching services:", error);
    } finally {
      setLoadingServices(false);
    }
  }

  async function fetchProductsAndServices() {
    try {
      setLoadingServices(true);
      setLoadingProducts(true);
      const productResponse = await fetchOptions({
        bpScenarioId,
        optionKey: "products",
      });
      const serviceResponse = await fetchOptions({
        bpScenarioId,
        optionKey: "services",
      });

      setOptions((prevOptions) => ({
        ...prevOptions,
        services: serviceResponse.SectionData.Options.services,
        products: productResponse.SectionData.Options.products,
      }));
      setServiceCards(transformToSelectable(serviceResponse.SectionData.Options.services));
      setProductCards(transformToSelectable(productResponse.SectionData.Options.products));
    } catch (error) {
      console.error("Error fetching services:", error);
    } finally {
      setLoadingServices(false);
      setLoadingProducts(false);
    }
  }

  useEffect(() => {
    if (isLoading) return;

    if (businessProductsAndServices) {
      if (businessProductsAndServices.products) {
        setProductCards((prev) =>
          transformToSelectable(options?.products || prev, businessProductsAndServices.products),
        );
      }
      if (businessProductsAndServices.services) {
        setServiceCards((prev) =>
          transformToSelectable(options?.services || prev, businessProductsAndServices.services),
        );
      }
    }
  }, [businessProductsAndServices]);

  useEffect(() => {
    if (options) {
      setInitialRender(false);
      if (options?.products.length > 0 && options?.services.length > 0 && initialRender) {
        setProductCards(
          transformToSelectable(options.products, businessProductsAndServices.products),
        );

        setServiceCards(
          transformToSelectable(options.services, businessProductsAndServices.services),
        );
      } else {
        if (options.products.length === 0 || options.services.length === 0) {
          fetchProductsAndServices();
        }
      }
    }
  }, [options]);

  function handleInputChange(event, isProduct) {
    if (isProduct) {
      setProductInputValue(event.target.value);
    } else {
      setServiceInputValue(event.target.value);
    }
  }

  function addCard(isProduct = true) {
    let trimmedValue = isProduct ? productInputValue.trim() : serviceInputValue.trim();
    if (!trimmedValue) return;

    const cardExists = isProduct
      ? productCards.some((card) => card.title === trimmedValue)
      : serviceCards.some((card) => card.title === trimmedValue);

    if (cardExists) {
      setInputError(true);
      return;
    }

    const newCard = { title: trimmedValue, isSelected: false };
    if (isProduct) {
      setProductInputValue("");
      setIsProductInputActive(false);
      setProductCards((prevCards) => [...prevCards, newCard]);
      setNewProductsCardOptions((prevCards) => [...prevCards, newCard.title]);
    } else {
      setServiceInputValue("");
      setIsServiceInputActive(false);
      setServiceCards((prevCards) => [...prevCards, newCard]);
      setNewServiceCardOptions((prevCards) => [...prevCards, newCard.title]);
    }
    setInputError(false);
  }

  function addServiceCard() {
    addCard(false);
  }
  function addProductCard() {
    addCard(true);
  }

  function onProductCardClick(index) {
    setProductsError(false);
    setServicessError(false);
    setProductCards((prevCards) =>
      prevCards.map((card, i) => (i === index ? { ...card, isSelected: !card.isSelected } : card)),
    );
  }

  function onServiceCardClick(index) {
    setServicessError(false);
    setProductsError(false);
    setServiceCards((prevCards) =>
      prevCards.map((card, i) => (i === index ? { ...card, isSelected: !card.isSelected } : card)),
    );
  }

  function handleSaveEdit(index, newTitle, isProduct = true) {
    if (isProduct) {
      setProductCards((prevCards) =>
        prevCards.map((card, i) => (i === index ? { ...card, title: newTitle } : card)),
      );
    } else {
      setServiceCards((prevCards) =>
        prevCards.map((card, i) => (i === index ? { ...card, title: newTitle } : card)),
      );
    }
  }

  function validate() {
    if (
      productCards.some((card) => card.isSelected === true) ||
      serviceCards.some((card) => card.isSelected === true)
    ) {
      return true;
    }
    setServicessError(true);
    setProductsError(true);
    return false;
  }

  async function onNextHandle() {
    if (
      productCards.every((card) => !card.isSelected) &&
      serviceCards.every((card) => !card.isSelected)
    ) {
      return "business_overview/intelectual_property";
    }

    await setBusinessProductsAndServices({
      products: productCards.filter((card) => card.isSelected).map((card) => card.title),
      services: serviceCards.filter((card) => card.isSelected).map((card) => card.title),
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();
          await generateSection(
            businessProductsAndServicesObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });

    return "business_overview/intelectual_property";
  }

  async function onGenerateHandle() {
    if (!validate()) return;

    await setBusinessProductsAndServices({
      products: productCards.filter((card) => card.isSelected).map((card) => card.title),
      services: serviceCards.filter((card) => card.isSelected).map((card) => card.title),
      mode: SectionMode.Edit,
    });
    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();
          await generateSection(
            businessProductsAndServicesObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });

    return sectionName;
  }

  async function onSave() {
    await setBusinessProductsAndServices({
      products: productCards.filter((card) => card.isSelected).map((card) => card.title),
      services: serviceCards.filter((card) => card.isSelected).map((card) => card.title),
      mode: SectionMode.Edit,
    });
    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();
          await saveSectionInputs(
            businessProductsAndServicesObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });
  }

  function onRegenerateProducts() {
    fetchProducts();
    setProductCards(
      productCards.map((card) => {
        return { ...card, isSelected: false };
      }),
    );
  }

  function onRegenerateService() {
    fetchServices();
    setServiceCards(
      productCards.map((card) => {
        return { ...card, isSelected: false };
      }),
    );
  }

  return (
    <>
      <PromptWrapper>
        <QuestionWrapper
          error={productsError}
          isAnswered={productCards.some((card) => card.isSelected)}
          question={"What products will your business sell?"}
          description={
            "Define the products that you plan to sell. Services that you sell will be defined later. Skip if you don't offer products"
          }
          hasSelectOptions
          onRegenerateOptions={() => onRegenerateProducts()}
        >
          <div className="grid grid-cols-1 gap-3 auto-rows-fr">
            {loadingProducts ? (
              <CircleLoader />
            ) : (
              productCards.map((card, index) => (
                <CardSelect
                  value={card.title}
                  onClick={() => onProductCardClick(index)}
                  key={`select-product-card-${index}`}
                  isSelected={card.isSelected}
                  onSaveEdit={(i, newValue) => handleSaveEdit(i, newValue, true)}
                  index={index}
                >
                  {card.title}
                </CardSelect>
              ))
            )}

            {isProductInputActive && (
              <Input
                error={inputError}
                placeholder="Add new product"
                value={productInputValue}
                onChange={(e) => handleInputChange(e, true)}
                onAddCard={addProductCard}
              />
            )}
          </div>

          <Button className={"w-38"} rounded outline onClick={() => setIsProductInputActive(true)}>
            Add More
          </Button>
        </QuestionWrapper>

        <QuestionWrapper
          error={servicesError}
          isAnswered={serviceCards.some((card) => card.isSelected)}
          question={"What services will your business provide?"}
          description={
            "Now define the services your business will offer. Skip if you don't offer services"
          }
          hasSelectOptions
          onRegenerateOptions={() => onRegenerateService()}
        >
          <div className="grid grid-cols-1 gap-3 auto-rows-fr">
            {loadingServices ? (
              <CircleLoader />
            ) : (
              serviceCards.map((card, index) => (
                <CardSelect
                  value={card.title}
                  onClick={() => onServiceCardClick(index)}
                  key={`select-service-card-${index}`}
                  isSelected={card.isSelected}
                  onSaveEdit={(i, newValue) => handleSaveEdit(i, newValue, false)}
                  index={index}
                >
                  {card.title}
                </CardSelect>
              ))
            )}

            {isServiceInputActive && (
              <Input
                error={inputError}
                placeholder="Add new service"
                value={serviceInputValue}
                onChange={(e) => handleInputChange(e, false)}
                onAddCard={addServiceCard}
              />
            )}
          </div>

          <Button className={"w-38"} rounded outline onClick={() => setIsServiceInputActive(true)}>
            Add More
          </Button>
        </QuestionWrapper>
      </PromptWrapper>

      <FormNavigation
        onNext={onNextHandle}
        onGenerate={onGenerateHandle}
        onSave={onSave}
        initialState={businessProductsAndServices}
        newState={{
          products:
            productCards.filter((card) => card.isSelected).map((card) => card.title).length > 0
              ? productCards.filter((card) => card.isSelected).map((card) => card.title)
              : undefined,
          services:
            serviceCards.filter((card) => card.isSelected).map((card) => card.title).length > 0
              ? serviceCards.filter((card) => card.isSelected).map((card) => card.title)
              : undefined,
        }}
      />
    </>
  );
}
