import { useNavigate } from "react-router-dom";
import { BusinessPlanCard } from "../components/BusinessPlanCard";
import { Button } from "../components/CopiedFromRefactor/Button";
import { Header } from "../components/Header";
import { usePage } from "../contexts/PageProvider";
import { useEffect } from "react";

const NAVBAR_HEIGHT = "68px";
const NAVIGATION_HEIGHT = "64px";
const HEADER_HEIGHT = "112px";
const PROMPT_PADDING = "10px";

export function PageWrapper(props) {
  const navigate = useNavigate();
  const { page, section } = props;
  const { isLoading } = usePage();

  useEffect(() => {
    const container = document.getElementById("MainContainer");
    if (container) {
      container.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [page]);

  const { setPage, setSection } = usePage();

  useEffect(() => {
    if (!global.Modeliks.BusinessPlanScenarioInfo.OpenedAIWizard) {
      global.Modeliks.BusinessPlanScenarioInfo.OpenedAIWizard = true;
      global.Modeliks.BusinessPlanScenarioInfo.Save();
    }
  }, []);

  useEffect(() => {
    setPage(page);
    setSection(section);
  }, [page, section]);

  function onNavigate(index) {
    if (isLoading) return;

    if (index === 1) {
      navigate("/business_plan/ai_generator");
    } else {
      navigate("/business_plan");
    }
  }

  return (
    <div
      className={`flex flex-col bg-white relative overflow-hidden`}
      style={{
        maxHeight: `calc(100vh - ${NAVBAR_HEIGHT})`,
      }}
    >
      <Header section={section} index={page.index} page={page.name} />
      <div
        className={`flex justify-between gap-5 pb-2 pt-8 px-10 2xl:px-36 items-start h-full overflow-scroll`}
        style={{
          maxHeight: `calc(100vh - ${NAVBAR_HEIGHT} - ${NAVIGATION_HEIGHT} - ${HEADER_HEIGHT} + ${PROMPT_PADDING})`,
        }}
      >
        <div className={`w-full max-w-6xl flex flex-col gap-12`}>{section.prompt}</div>
        <div className="flex flex-col gap-2 sticky top-0 min-w-68 2xl:min-w-92">
          <BusinessPlanCard
            isFormCard
            selectedSection={section.title}
            index={page.index}
            title={page.name}
            sections={page.sections}
            url={page.url}
          />
          <div className="grid grid-cols-2 gap-4">
            <Button rounded outline className="font-bold" onClick={() => onNavigate(1)}>
              Plan Overview
            </Button>

            <Button rounded outline className="font-bold" onClick={() => onNavigate(2)}>
              View Plan
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
