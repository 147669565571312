import { useState, useEffect, useRef } from "react";
import { Button } from "./CopiedFromRefactor/Button";
import { twMerge } from "tailwind-merge";
import { useOutsideClick } from "./CopiedFromRefactor/useOutsideClick";

export function CardSelect(props) {
  const { className, isSelected, children, onClick, value, onSaveEdit, index, nonEditable } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [inputValues, setInputValues] = useState(Array.isArray(value) ? value : [value]);
  const [initialValues, setInitialValues] = useState(Array.isArray(value) ? value : [value]);

  const inputRefOne = useRef(null);
  const inputRefTwo = useRef(null);

  const isSelectedStyles = isSelected
    ? "text-white bg-primary hover:bg-primary hover:text-white"
    : "text-black-primary bg-white hover:bg-blue-light-4 hover:text-black-primary";

  useEffect(() => {
    setInputValues(Array.isArray(value) ? value : [value]);
    setInitialValues(Array.isArray(value) ? value : [value]);
  }, [value]);

  useEffect(() => {
    if (isEditing) {
      [inputRefOne.current, inputRefTwo.current].forEach((textarea) => {
        if (textarea) adjustTextareaHeight(textarea);
      });
    }
  }, [isEditing]);

  function adjustTextareaHeight(textarea) {
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  function onCardClick() {
    if (!isEditing) onClick(value);
  }

  function onEditClick(e) {
    e.stopPropagation();
    setIsEditing(true);
  }

  function onSaveClick() {
    setIsEditing(false);
    const updatedValues = inputValues.map((inputValue, idx) =>
      inputValue.trim() === "" ? initialValues[idx] : inputValue,
    );
    setInputValues(updatedValues);

    if (updatedValues.length > 1) {
      onSaveEdit(index, updatedValues);
    } else {
      onSaveEdit(index, updatedValues[0]);
    }
  }

  useOutsideClick({ current: inputRefOne.current || inputRefTwo.current }, onSaveClick);

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      onSaveClick();
    }
  }

  function handleChange(e, idx) {
    const updatedValues = [...inputValues];
    updatedValues[idx] = e.target.value;
    setInputValues(updatedValues);
  }

  const style = twMerge(
    `group py-8 px-2 text-center flex items-center justify-center h-full rounded-md relative cursor-pointer border-2 border-primary hover:border-primary hover:shadow-light ${isSelectedStyles}`,
    className,
  );

  return (
    <div onClick={onCardClick} className={style}>
      {!nonEditable && (
        <>
          {isEditing ? (
            <Button
              size="small"
              className={`min-w-12 absolute top-2 right-2 ${isSelected ? "bg-grey-light-3 text-white" : ""}`}
              onClick={onSaveClick}
            >
              Save
            </Button>
          ) : (
            <Button
              size="small"
              className={`hidden group-hover:inline-flex min-w-12 absolute top-2 right-2 ${isSelected ? "bg-grey-light-3 text-white" : ""}`}
              onClick={(e) => onEditClick(e)}
            >
              Edit
            </Button>
          )}
        </>
      )}

      {isEditing ? (
        <div className={`flex items-center gap-3 w-full`}>
          {inputValues.map((inputValue, idx) => (
            <textarea
              placeholder="..."
              key={`input-${idx}`}
              ref={idx === 0 ? inputRefOne : inputRefTwo}
              className={`text-18 w-full bg-transparent focus:outline-none border-b border-grey-light-3 resize-none overflow-hidden`}
              value={inputValue}
              onChange={(e) => handleChange(e, idx)}
              onInput={(e) => adjustTextareaHeight(e.target)}
              onKeyDown={handleKeyDown}
            />
          ))}
        </div>
      ) : (
        <span className={"px-1 text-16 2xl:text-18"}>{children}</span>
      )}
    </div>
  );
}
