import { twMerge } from "tailwind-merge";
import { useOutsideClick } from "./CopiedFromRefactor/useOutsideClick";
import { useRef } from "react";

export function Input(props) {
  const {
    placeholder,
    type = "text",
    className,
    value = "",
    onChange,
    onKeyPress,
    title,
    error,
    onAddCard,
  } = props;
  const inputRef = useRef(null);

  const style = twMerge(
    `border rounded-md w-full h-full text-18 p-2 focus:outline-none focus:border-primary min-h-9 2xl:min-h-16 ${error ? "!border-red-primary" : "border-grey-light-3"}`,
    className,
  );

  if (onAddCard) useOutsideClick(inputRef, onAddCard);

  function handleKeyPress(event) {
    if (onAddCard)
      if (event.key === "Enter") {
        onAddCard();
      }
  }

  return (
    <div className="flex flex-col gap-2 h-full">
      {title && <span className="text-16 2xl:text-18 font-bold">{title}</span>}
      <input
        ref={inputRef}
        value={value}
        onChange={onChange}
        onKeyPress={handleKeyPress}
        type={type}
        placeholder={placeholder}
        className={style}
      />
    </div>
  );
}
