import { iconRegenerate, iconSelect } from "../icons";
import { CheckedBox } from "./CheckedBox";

const selectIcon = iconSelect;
const regenerateIcon = iconRegenerate;

export function QuestionWrapper(props) {
  const {
    question,
    description,
    children,
    isAnswered,
    hasSelectOptions,
    maxSelectiveOptions,
    onRegenerateOptions,
    isOptional,
    error,
  } = props;

  return (
    <div
      className={`flex flex-col gap-6 rounded-2xl shadow p-7 border ${error ? "border-red-primary" : "border-transparent"}`}
    >
      <div className="flex gap-5 w-full">
        <CheckedBox isChecked={isAnswered} />
        <div className="flex flex-col gap-3 2xl:gap-6 grow">
          {(question || description) && (
            <div className="flex flex-col gap-1 2xl:gap-4">
              <span className="text-20 2xl:text-24 font-bold">
                {question} {isOptional && <span className="text-grey-dark-1">(optional)</span>}
              </span>
              <span className="text-14 2xl:text-18">{description}</span>
            </div>
          )}
          {(hasSelectOptions || maxSelectiveOptions || onRegenerateOptions) && (
            <div className="flex justify-between text-primary">
              {hasSelectOptions && (
                <div className="flex items-center gap-1 text-grey-dark-2">
                  {selectIcon}Select options
                </div>
              )}
              {maxSelectiveOptions && (
                <div className="flex items-center gap-1 text-grey-dark-2">
                  {selectIcon}Select maximum {maxSelectiveOptions} options
                </div>
              )}
              {!maxSelectiveOptions && !hasSelectOptions && <div></div>}
              {onRegenerateOptions && (
                <div
                  className="cursor-pointer flex items-center gap-1"
                  onClick={() => onRegenerateOptions()}
                >
                  {regenerateIcon}Regenerate options{" "}
                </div>
              )}
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
}
