import { Link, useNavigate } from "react-router-dom";
import { BusinessPlanCard } from "./components/BusinessPlanCard";
import { cards, SectionUrls } from "./config";
import { Button } from "./components/CopiedFromRefactor/Button";
import BusinessPlanScenarioMenu from "../../SlideEditor/components/components/menus/BusinessPlanScenarioMenu/BusinessPlanScenarioMenu";
import { useEffect, useState } from "react";
import { usePage } from "./contexts/PageProvider";
import { iconArrow } from "./icons";
import { handleScenarioChange, postBusinessPlanScenario } from "./hooks/businessPlanGenerator";
import { Menu, MenuItem } from "@szhsin/react-menu";
import ShareDialog from "../../SlideEditor/components/components/Dialog/ShareDialog";
import { AccessControl } from "../../data/Permissions/AccessControl";
import UpdatePlan from "../../components/dialogs/LimitDialogs/UpdatePlan";
import CircleLoader from "../../components/Loaders/CircleLoader/CircleLoader";

export function AiGenerator() {
  const navigate = useNavigate();

  const { inputs, businessDescription } = usePage();
  const [isBusinessPlanEdited, setIsBusinessPlanEdited] = useState(false);
  const [lastEditedSectionUrl, setLastEditedSectionUrl] = useState("business_overview/description");
  const [businessPlanName, setBusinessPlanName] = useState(
    global.Modeliks.BusinessPlanScenarioInfo.Name,
  );
  const [loading, setLoading] = useState(false);

  const [isShareDialogOpened, setIsShareDialogOpened] = useState(false);
  const [isFreeTrialDialogOpened, setIsFreeTrialDialogOpened] = useState(false);

  function checkNestedValues(value) {
    if (value === undefined || value === null) {
      return true;
    }
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }
    if (typeof value === "object" && !Array.isArray(value)) {
      return Object.values(value).some((nestedValue) => checkNestedValues(nestedValue));
    }
    return false;
  }

  useEffect(() => {
    if (inputs) {
      if (!checkNestedValues(businessDescription)) {
        setIsBusinessPlanEdited(true);
      }

      for (const key in inputs) {
        if (inputs.hasOwnProperty(key)) {
          const value = inputs[key];
          if (checkNestedValues(value)) {
            setLastEditedSectionUrl(SectionUrls[key]);
            return;
          }
        }
      }
    }
  }, [inputs]);

  function openShareDialog() {}

  useEffect(() => {
    if (!global.Modeliks.BusinessPlanScenarioInfo.OpenedAIWizard) {
      global.Modeliks.BusinessPlanScenarioInfo.OpenedAIWizard = true;
      global.Modeliks.BusinessPlanScenarioInfo.Save();
    }
  }, []);

  return (
    <div
      className={`flex flex-col items-center  p-4 bg-white ${loading ? "justify-start gap-24" : " justify-between gap-3"}`}
    >
      <div className="flex flex-col items-center gap-5 2xl:gap-6">
        <span className="text-36 2xl:text-60 font-semibold text-center">
          Create your business plan with AI
        </span>
        <div className="text-center flex flex-col gap-2">
          <span className="text-14 2xl:text-18 max-w-screen-xl">
            Your business plan is organized into chapters, with each chapter containing several
            sections. To complete your plan, navigate through each chapter and fill in the
            corresponding sections. Be sure to finish the &quot;Executive Summary&quot; chapter
            last. You can also customize the cover page as needed.
            <br />
            Please use English as a primary language for building your business plan.
          </span>
        </div>
      </div>

      {loading ? (
        <div className="overflow-hidden">
          <CircleLoader />
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-2 w-full max-w-[1700px]">
            <BusinessPlanScenarioMenu
              menuButton={
                <span className="cursor-pointer text-grey-dark-3 font-bold text-18 2xl:text-32 flex gap-1 2xl:gap-2 items-center w-fit pr-2">
                  Plan Name: {businessPlanName}
                  <div className="w-2 h-2 2xl:w-3 2xl:h-5">{iconArrow}</div>
                </span>
              }
              isInAiGenerator={true}
              changeScenario={handleScenarioChange}
              setSaving={() => {}}
              onChangeName={setBusinessPlanName}
              postBusinessPlanScenario={postBusinessPlanScenario}
            />

            <div className="justify-center gap-6 grid grid-cols-5">
              {cards.map((card, index) => {
                return (
                  <BusinessPlanCard
                    url={card.url}
                    icon={card.icon}
                    sections={card.sections}
                    title={card.name}
                    key={`business-card-${index}`}
                    index={index + 1}
                    setLoading={setLoading}
                  />
                );
              })}
            </div>
          </div>

          <div className="flex flex-col gap-3 items-center">
            <div className="grid grid-cols-2 gap-8 2xl:gap-12 max-w-screen-md">
              <Link to={`/business_plan/ai_generator/${lastEditedSectionUrl}`}>
                <Button className={"min-w-64 2xl:min-w-96 py-2 2xlpy-4"} rounded>
                  <span className="text-16 2xl:text-24">
                    {isBusinessPlanEdited
                      ? "Continue your business plan"
                      : "Start your business plan"}
                  </span>
                </Button>
              </Link>
              <Link to="/business_plan">
                <Button
                  className={"min-w-64 2xl:min-w-96 py-2 2xlpy-4"}
                  outline
                  rounded
                  size="large"
                >
                  <span className="text-16 2xl:text-24"> View plan</span>
                </Button>
              </Link>
            </div>

            <UpdatePlan
              handleClose={() => setIsFreeTrialDialogOpened(false)}
              onCancel={() => setIsFreeTrialDialogOpened(false)}
              open={isFreeTrialDialogOpened}
            />

            <AccessControl
              allowedPermissions={[
                {
                  [true
                    ? global.Modeliks.PERMISSIONS.BusinessPlan.key
                    : global.Modeliks.PERMISSIONS.Pitch.key]: true
                    ? global.Modeliks.PERMISSIONS.BusinessPlan.restrictions.ExportPDF.key
                    : global.Modeliks.PERMISSIONS.Pitch.restrictions.ExportPDF.key,
                },
                {
                  [true
                    ? global.Modeliks.PERMISSIONS.BusinessPlan.key
                    : global.Modeliks.PERMISSIONS.Pitch.key]: true
                    ? global.Modeliks.PERMISSIONS.BusinessPlan.restrictions.ShareLink.key
                    : global.Modeliks.PERMISSIONS.Pitch.restrictions.ShareLink.key,
                },
              ]}
              renderNoAccess={
                <p
                  onClick={() => setIsFreeTrialDialogOpened(true)}
                  className="text-12 2xl:text-16 text-primary underline font-bold cursor-pointer"
                >
                  Share & Downloads Plan
                </p>
              }
            >
              <Menu
                className={"hd_rc_share_menu"}
                menuButton={
                  <p className="text-12 2xl:text-16 text-primary underline font-bold cursor-pointer">
                    Share & Downloads Plan
                  </p>
                }
                transition
              >
                <ShareDialog
                  ScenarioID={global.Modeliks.BusinessPlanScenarioInfo.ID}
                  type={undefined}
                  ScenarioName={undefined}
                  ScenarioNamePrefix={undefined}
                  openFreeTrialDialog={openShareDialog}
                  businessPlan={true}
                />
                <MenuItem
                  onClick={() => {
                    navigate("/business_plan", { state: { exportToPdf: true } });
                  }}
                  className={"hd_rc_sm_menu_item"}
                >
                  Export to PDF
                </MenuItem>
              </Menu>
            </AccessControl>
          </div>
        </>
      )}
    </div>
  );
}
