import { useEffect, useState } from "react";
import { Button } from "../../components/CopiedFromRefactor/Button";
import { CardSelect } from "../../components/CardSelect";
import { QuestionWrapper } from "../../components/QuestionWrapper";
import { Input } from "../../components/Input";
import { usePage } from "../../contexts/PageProvider";
import { SectionMode, SuccessFactorCompetitorsEnum } from "../../helpers/enums";
import { useSave } from "../../hooks/useSave";
import { marketCriticalSuccessFactorsObjectToAiGeneratedContentDto } from "../../models/aiGeneratedContent/transformer";
import { fetchOptions } from "../../Services/api/Repositories/BusinessPlanGenerator";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import { Menu, MenuItem } from "@mui/material";
import { FormNavigation } from "../../components/FormNavigation";
import { PromptWrapper } from "../../components/PromptWrapper";

const MAX_CARDS = 10;

export function CriticalSuccessFactor() {
  const {
    section,
    marketCriticalSuccessFactor,
    setMarketCriticalSuccessFactor,
    businessDescription,
    marketCompetitors,
    getGeneratedData,
    setLoading,
    isLoading,
    options,
    setOptions,
  } = usePage();

  const [sectionName, setSectionName] = useState({ sectionName: "" });
  const { nextSection, generateSection, saveSectionInputs } = useSave(sectionName);

  const [cards, setCards] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inputError, setInputError] = useState(false);
  const [isInputActive, setIsInputActive] = useState(false);
  const [loadingFactors, setLoadingFactors] = useState(false);
  const [competitorsSuccessFactors, setCompetitorsSuccessFactors] = useState([]);
  const [anchorEls, setAnchorEls] = useState([[]]);
  const [initialLoad, setInitialLoad] = useState(false);
  const [newCriticalSuccessFactorOptions, setNewCriticalSuccessFactorOptions] = useState([]);

  useEffect(() => {
    if (options)
      setOptions({
        ...options,
        criticalSuccessFactors: [
          ...options.criticalSuccessFactors,
          ...newCriticalSuccessFactorOptions.filter(
            (card) => !options.criticalSuccessFactors.includes(card),
          ),
        ],
      });
  }, [newCriticalSuccessFactorOptions]);

  const store = new Map();

  function getSuccessFactorValue(map, successFactorTitle, entityName) {
    return map[`${successFactorTitle}${entityName}`];
  }

  useEffect(() => {
    if (businessDescription?.companyName && marketCompetitors?.competitors?.length > 0) {
      cards
        .filter((card) => card.isSelected)
        .forEach((successFactor) => {
          store.set(
            successFactor.title + businessDescription.companyName,
            SuccessFactorCompetitorsEnum.Average,
          );

          marketCompetitors.competitors.forEach((competitor) => {
            store.set(
              successFactor.title + competitor.competitorName,
              SuccessFactorCompetitorsEnum.Average,
            );
          });
        });
    }
  }, [businessDescription, marketCompetitors]);

  useEffect(() => {
    if (
      !initialLoad &&
      !isLoading &&
      marketCriticalSuccessFactor &&
      marketCompetitors &&
      businessDescription
    ) {
      setInitialLoad(true);
      if (!marketCriticalSuccessFactor.successFactorCompetitors) {
        setCompetitorsSuccessFactors(
          cards
            .filter((card) => card.isSelected)
            .map((successFactor) => {
              const companyRow = [
                store.get(
                  successFactor.title + businessDescription.companyName,
                  SuccessFactorCompetitorsEnum.Best,
                ),
              ];

              const competitorsRow = marketCompetitors.competitors.map((competitor) => {
                return store.get(
                  successFactor.title + competitor.competitorName,
                  SuccessFactorCompetitorsEnum.Best,
                );
              });

              return [...companyRow, ...competitorsRow];
            }),
        );
      } else {
        let savedCompetitorsSuccessFactors = [];

        cards
          .filter((card) => card.isSelected)
          .map((successFactor, index) => {
            if (!savedCompetitorsSuccessFactors[index]) {
              savedCompetitorsSuccessFactors[index] = [];
            }

            savedCompetitorsSuccessFactors[index][0] = getSuccessFactorValue(
              marketCriticalSuccessFactor.successFactorCompetitors,
              successFactor.title,
              businessDescription.companyName,
            );
          });

        marketCompetitors.competitors.map((competitor, competitorIndex) => {
          cards
            .filter((card) => card.isSelected)
            .map((successFactor, index) => {
              if (!savedCompetitorsSuccessFactors[index]) {
                savedCompetitorsSuccessFactors[index] = [];
              }

              savedCompetitorsSuccessFactors[index][competitorIndex + 1] =
                getSuccessFactorValue(
                  marketCriticalSuccessFactor.successFactorCompetitors,
                  successFactor.title,
                  competitor.competitorName,
                ) || SuccessFactorCompetitorsEnum.Average;
            });
        });

        setCompetitorsSuccessFactors(savedCompetitorsSuccessFactors);
      }
    }
  }, [cards]);

  useEffect(() => {
    if (section) {
      setSectionName({ sectionName: section.name });
    }
  }, [section]);

  const bpScenarioId = global.Modeliks.BusinessPlanScenarioInfo.ID;

  function transformToSelectable(items = [], selectedItems = []) {
    const allItems = items.map((item) => ({
      title: item,
      isSelected: selectedItems.includes(item),
    }));

    selectedItems.forEach((selectedItem) => {
      if (!items.includes(selectedItem)) {
        allItems.push({ title: selectedItem, isSelected: true });
      }
    });

    return allItems;
  }

  async function fetchCriticalFactors() {
    try {
      setLoadingFactors(true);
      const factorsResponse = await fetchOptions({
        bpScenarioId,
        optionKey: "criticalSuccessFactors",
      });
      setOptions({
        ...options,
        criticalSuccessFactors: factorsResponse.SectionData.Options.criticalSuccessFactors,
      });
      setCards(transformToSelectable(factorsResponse.SectionData.Options.criticalSuccessFactors));
    } catch (error) {
      console.error("Error fetching critical success factors:", error);
    } finally {
      setLoadingFactors(false);
    }
  }

  useEffect(() => {
    if (isLoading) return;

    if (marketCriticalSuccessFactor) {
      if (marketCriticalSuccessFactor.criticalSuccessFactors) {
        setCards((prev) =>
          transformToSelectable(
            options?.criticalSuccessFactors || prev,
            marketCriticalSuccessFactor.criticalSuccessFactors,
          ),
        );
      }

      if (marketCriticalSuccessFactor.successFactorCompetitors) {
        setCompetitorsSuccessFactors(marketCriticalSuccessFactor.successFactorCompetitors);
      }
    }

    if (options)
      if (options.criticalSuccessFactors.length === 0) {
        setInitialLoad(true);
        fetchCriticalFactors();
      } else {
        if (!initialLoad) return;
        setCards(
          transformToSelectable(
            options.criticalSuccessFactors,
            marketCriticalSuccessFactor?.criticalSuccessFactors,
          ),
        );
      }
  }, [options, marketCriticalSuccessFactor, isLoading]);

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  function addCard() {
    const trimmedValue = inputValue.trim();
    if (!trimmedValue) return;

    const cardExists = cards.some((card) => card.title === trimmedValue);
    if (cardExists) {
      setInputError(true);
      return;
    }

    const newCard = { title: trimmedValue, isSelected: false };
    setCards((prevCards) => [...prevCards, newCard]);
    setNewCriticalSuccessFactorOptions((prevCards) => [...prevCards, newCard.title]);
    setInputValue("");
    setIsInputActive(false);
    setInputError(false);
  }

  function onCardClick(index) {
    const selectedCount = cards.filter((card) => card.isSelected).length;

    setCards((prevCards) => {
      const updatedCards = prevCards.map((card, i) =>
        i === index
          ? { ...card, isSelected: card.isSelected ? false : selectedCount < MAX_CARDS }
          : card,
      );

      const selectedCards = updatedCards.filter((card) => card.isSelected);

      const newCompetitorsSuccessFactors = selectedCards.map((card, successFactorIndex) => {
        if (!competitorsSuccessFactors[successFactorIndex]) {
          const companyRow = [SuccessFactorCompetitorsEnum.Best];
          const competitorsRow = marketCompetitors.competitors.map(
            () => SuccessFactorCompetitorsEnum.Average,
          );
          return [...companyRow, ...competitorsRow];
        }
        return competitorsSuccessFactors[successFactorIndex];
      });

      setCompetitorsSuccessFactors(newCompetitorsSuccessFactors);
      return updatedCards;
    });
  }

  function handleSaveEdit(index, newTitle) {
    setCards((prevCards) =>
      prevCards.map((card, i) => (i === index ? { ...card, title: newTitle } : card)),
    );
  }

  function validate() {
    return cards.filter((card) => card.isSelected).length > 0;
  }

  async function onNextHandle() {
    await setMarketCriticalSuccessFactor({
      criticalSuccessFactors: cards.filter((card) => card.isSelected).map((card) => card.title),
      successFactorCompetitors: transformCriticalSuccessFactors(),
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await nextSection(
            marketCriticalSuccessFactorsObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });

    return "market_analysis/unique_selling_proposition";
  }

  async function onGenerateHandle() {
    if (!validate()) return;

    await setMarketCriticalSuccessFactor({
      criticalSuccessFactors: cards.filter((card) => card.isSelected).map((card) => card.title),
      successFactorCompetitors: transformCriticalSuccessFactors(),
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await generateSection(
            marketCriticalSuccessFactorsObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });

    return sectionName;
  }

  function transformCriticalSuccessFactors() {
    if (
      !marketCompetitors ||
      !businessDescription ||
      !marketCriticalSuccessFactor ||
      !Array.isArray(competitorsSuccessFactors)
    )
      return undefined;

    let successFactorCompetitorsValue = {};

    cards
      .filter((card) => card.isSelected)
      .map((successFactor, index) => {
        successFactorCompetitorsValue[`${successFactor.title}${businessDescription.companyName}`] =
          competitorsSuccessFactors[index][0];
      });

    marketCompetitors?.competitors?.map((competitor, competitorIndex) => {
      cards
        .filter((card) => card.isSelected)
        .map((successFactor, index) => {
          successFactorCompetitorsValue[`${successFactor.title}${competitor.competitorName}`] =
            competitorsSuccessFactors[index][competitorIndex + 1];
        });
    });

    return Object.keys(successFactorCompetitorsValue).length > 0
      ? successFactorCompetitorsValue
      : undefined;
  }

  async function onSave() {
    await setMarketCriticalSuccessFactor({
      criticalSuccessFactors: cards.filter((card) => card.isSelected).map((card) => card.title),
      successFactorCompetitors: transformCriticalSuccessFactors(),
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await saveSectionInputs(
            marketCriticalSuccessFactorsObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });
  }

  const handleDropdownClick = (successFactorIndex, competitorIndex, event) => {
    const newAnchorEls = [...anchorEls];
    if (!newAnchorEls[successFactorIndex]) {
      newAnchorEls[successFactorIndex] = [];
    }

    if (event) {
      newAnchorEls[successFactorIndex][competitorIndex] = event.currentTarget;
    }

    setAnchorEls(newAnchorEls);
  };

  const handleDropdownClose = (successFactorIndex, competitorIndex) => {
    const newAnchorEls = [...anchorEls];
    if (newAnchorEls[successFactorIndex]) {
      newAnchorEls[successFactorIndex][competitorIndex] = null;
    }
    setAnchorEls(newAnchorEls);
  };

  const getMenuElement = (rating, hasText) => {
    switch (rating) {
      case SuccessFactorCompetitorsEnum.Best:
        return (
          <div className="flex items-center gap-2">
            <div className="w-10 h-10 rounded-full cursor-pointer bg-green-dark-2"></div>
            {hasText && <span>Best in class</span>}
          </div>
        );
      case SuccessFactorCompetitorsEnum.Average:
        return (
          <div className="flex items-center gap-2">
            <div className="w-10 h-10 rounded-full cursor-pointer bg-yellow-variant-1"></div>
            {hasText && <span>Average performance</span>}
          </div>
        );
      case SuccessFactorCompetitorsEnum.Poor:
        return (
          <div className="flex items-center gap-2">
            <div className="w-10 h-10 rounded-full cursor-pointer bg-red-primary"></div>
            {hasText && <span>Poor performance</span>}
          </div>
        );
    }
  };

  const handleMenuSelect = (competitorIndex, successFactorIndex, selectedRating) => {
    const updatedCompetitorsSuccessFactors = [...competitorsSuccessFactors];
    updatedCompetitorsSuccessFactors[successFactorIndex][competitorIndex] = selectedRating;
    setCompetitorsSuccessFactors(updatedCompetitorsSuccessFactors);
    handleDropdownClose(successFactorIndex, competitorIndex);
  };

  return (
    <>
      <PromptWrapper>
        <QuestionWrapper
          isAnswered={cards.some((card) => card.isSelected)}
          question={"What are your Critical Success Factors?"}
          description={"Define the critical success factors for your business."}
          maxSelectiveOptions={10}
          onRegenerateOptions={fetchCriticalFactors}
        >
          {loadingFactors ? (
            <CircleLoader />
          ) : (
            <div className="grid grid-cols-2 gap-3 auto-rows-fr">
              {cards.map((card, index) => (
                <CardSelect
                  value={card.title}
                  onClick={() => onCardClick(index)}
                  key={`select-card-${index}`}
                  isSelected={card.isSelected}
                  onSaveEdit={(i, newValue) => handleSaveEdit(i, newValue)}
                  index={index}
                >
                  {card.title}
                </CardSelect>
              ))}

              {isInputActive && (
                <Input
                  error={inputError}
                  placeholder="Add new critical success factor"
                  value={inputValue}
                  onChange={handleInputChange}
                  onAddCard={addCard}
                />
              )}
            </div>
          )}

          <Button className={"w-38"} rounded outline onClick={() => setIsInputActive(true)}>
            Add More
          </Button>
        </QuestionWrapper>

        {cards.some((card) => card.isSelected) &&
          competitorsSuccessFactors.length > 0 &&
          businessDescription && (
            <QuestionWrapper
              isAnswered={true}
              question={"How do you compare to your competitors?"}
              description={
                "Evaluate yourself and each competitor along the critical success factors. Green means best in class. Yellow means average performance. Red means poor performance or not available at all."
              }
            >
              <div
                className={`grid auto-rows-fr gap-y-3`}
                style={{
                  gridTemplateColumns: `repeat(${marketCompetitors.competitors.length + 2}, 1fr)`,
                }}
              >
                <span className="text-18 font-bold p-2">Critical Success Factors</span>
                <span className="text-18 font-bold text-primary text-center p-2">
                  {businessDescription.companyName}
                </span>
                {marketCompetitors.competitors.map((competitor, index) => (
                  <span
                    className="text-18 font-bold text-center p-2"
                    key={`competitor-header-${index}`}
                  >
                    {competitor.competitorName}
                  </span>
                ))}

                {cards
                  .filter((card) => card.isSelected)
                  .map((successFactor, successFactorIndex) => (
                    <>
                      <div
                        key={`successFactor-${successFactorIndex}`}
                        className="bg-primary flex items-center justify-center text-white text-center shadow rounded-md mr-3 text-18 font-bold p-2"
                      >
                        {successFactor.title}
                      </div>

                      {["", ...marketCompetitors.competitors].map((competitor, competitorIndex) => {
                        return (
                          <div
                            key={`competitor-factor-${successFactorIndex}-${competitorIndex}`}
                            className={`flex items-center justify-center text-center border-l border-t border-b border-grey-light-3 ${
                              competitorIndex === 0 ? "rounded-s-md" : ""
                            } ${
                              competitorIndex + 1 === ["", ...marketCompetitors.competitors].length
                                ? "rounded-e-md border-r"
                                : ""
                            }`}
                          >
                            <div
                              className="py-2 w-full h-full cursor-pointer hover:bg-blue-light-4 flex items-center justify-center"
                              onClick={(e) =>
                                handleDropdownClick(successFactorIndex, competitorIndex, e)
                              }
                            >
                              {getMenuElement(
                                competitorsSuccessFactors[successFactorIndex][competitorIndex],
                              )}
                            </div>

                            <Menu
                              anchorEl={anchorEls[successFactorIndex]?.[competitorIndex]}
                              open={Boolean(anchorEls[successFactorIndex]?.[competitorIndex])}
                              onClose={() =>
                                handleDropdownClose(successFactorIndex, competitorIndex)
                              }
                            >
                              <MenuItem
                                onClick={() =>
                                  handleMenuSelect(
                                    competitorIndex,
                                    successFactorIndex,
                                    SuccessFactorCompetitorsEnum.Best,
                                  )
                                }
                              >
                                <div onClick={(e) => handleDropdownClick(competitorIndex, e)}>
                                  {getMenuElement(SuccessFactorCompetitorsEnum.Best, true)}
                                </div>
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleMenuSelect(
                                    competitorIndex,
                                    successFactorIndex,
                                    SuccessFactorCompetitorsEnum.Average,
                                  )
                                }
                              >
                                <div onClick={(e) => handleDropdownClick(competitorIndex, e)}>
                                  {getMenuElement(SuccessFactorCompetitorsEnum.Average, true)}
                                </div>
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleMenuSelect(
                                    competitorIndex,
                                    successFactorIndex,
                                    SuccessFactorCompetitorsEnum.Poor,
                                  )
                                }
                              >
                                <div onClick={(e) => handleDropdownClick(competitorIndex, e)}>
                                  {getMenuElement(SuccessFactorCompetitorsEnum.Poor, true)}
                                </div>
                              </MenuItem>
                            </Menu>
                          </div>
                        );
                      })}
                    </>
                  ))}
              </div>
            </QuestionWrapper>
          )}
      </PromptWrapper>
      <FormNavigation
        onNext={onNextHandle}
        onGenerate={onGenerateHandle}
        onSave={onSave}
        initialState={marketCriticalSuccessFactor}
        newState={{
          criticalSuccessFactors:
            cards.filter((card) => card.isSelected).map((card) => card.title).length > 0
              ? cards.filter((card) => card.isSelected).map((card) => card.title)
              : undefined,
          successFactorCompetitors:
            cards.filter((card) => card.isSelected).map((card) => card.title).length > 0
              ? transformCriticalSuccessFactors()
              : undefined,
        }}
      />
    </>
  );
}
