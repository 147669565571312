import { useNavigate } from "react-router-dom";
import { Button } from "./CopiedFromRefactor/Button";
import { usePage } from "../contexts/PageProvider";
import CircleLoader from "../../../components/Loaders/CircleLoader/CircleLoader";
import { ButtonFunctionality, SectionName } from "../config";
import { BusinessPlanCardHeader } from "./BusinessPlanCardHeader";
import { useSave } from "../hooks/useSave";
import { executiveStrategyObjectToAiGeneratedContentDto } from "../models/aiGeneratedContent/transformer";
import { getSection } from "../hooks/getSection";

export function BusinessPlanCard(props) {
  const { businessDescription, inputs, isLoading, getGeneratedData, executiveSummary } = usePage();
  const { index, title, sections, selectedSection, url, icon, isFormCard, setLoading } = props;
  const { generateSection } = useSave({
    sectionName: SectionName.ExecutiveSummary,
  });
  const navigate = useNavigate();
  const bpScenarioId = global.Modeliks.BusinessPlanScenarioInfo.ID;

  function areAllValuesFilled(obj) {
    if (obj === null) return false;
    return Object.values(obj).every((value) => value !== null && value !== undefined);
  }

  function isEdited(propertyName) {
    if (!inputs) return false;

    if (Array.isArray(propertyName)) {
      return propertyName.some((name) => {
        const value = inputs[name];
        return value !== undefined && value !== null && checkValues(value);
      });
    }

    const value = inputs[propertyName];

    return value !== undefined && value !== null && checkValues(value);
  }

  function checkValues(value) {
    if (typeof value === "object" && value !== null) {
      return Object.values(value).some((val) => {
        if (Array.isArray(val)) {
          return val.length !== 0;
        }
        return val !== undefined && val !== null;
      });
    }
    return true;
  }

  async function onIconClick(functionality) {
    if (isLoading) return;

    if (functionality === ButtonFunctionality.Navigate) {
      navigate("/forecast");
    } else {
      await new Promise((resolve) => {
        setLoading(true);
        setTimeout(async () => {
          try {
            const generatedData = getGeneratedData();

            await generateSection(
              executiveStrategyObjectToAiGeneratedContentDto(generatedData, {
                sectionName: SectionName.ExecutiveSummary,
              }),
            );
            const section = await getSection(SectionName.ExecutiveSummary, bpScenarioId);

            if (section && section.PageID) {
              navigate("/business_plan", { state: { sectionId: section.PageID } });
            } else {
              console.error("Failed to retrieve section or PageId is missing.");
            }
          } catch (error) {
            console.error("Error during business plan generation:", error);
          } finally {
            setLoading(false);
          }

          resolve();
        }, 0);
      });
    }
  }

  function handleNavigateForSection(path) {
    if (!isLoading) navigate(path);
  }

  function canBeEdited(section) {
    if (!section) return false;

    return (
      section.name === SectionName.BusinessDescription ||
      (areAllValuesFilled(businessDescription) &&
        (!section.requiredSection || isEdited(section.requiredSection)))
    );
  }

  return (
    <div className={`flex flex-col shadow 2xl:min-h-88 rounded-lg`}>
      <BusinessPlanCardHeader
        title={title}
        index={index}
        isFormCard={isFormCard}
        indexSelected={index}
      />
      {isLoading && !isFormCard ? (
        <div className="grow flex items-center justify-center min-h-62 2xl:min-h-74">
          <CircleLoader />
        </div>
      ) : (
        <>
          {icon ? (
            <div className="flex flex-col items-center gap-4 py-8 h-full px-5">
              <span className="text-12 2xl:text-16 text-center">
                See your Forecast and analyze your future financial projections.
              </span>
              <div
                onClick={() => onIconClick(icon.function)}
                className="cursor-pointer border shadow border-primary rounded-md max-w-36 flex flex-col gap-1.5 items-center p-2 2xl:p-3  hover:bg-blue-light-4 "
              >
                <div className="max-h-10 2xl:max-h-none">{icon.icon}</div>
                <span
                  className="text-12 2xl:text-16 text-primary text-center font-bold max-w-20 2xl:max-w-28"
                  style={{ wordBreak: "break-word" }}
                >
                  {index === 5
                    ? executiveSummary
                      ? "Regenerate Executive Summary"
                      : "Generate Executive Summary"
                    : icon.text}
                </span>
              </div>
            </div>
          ) : (
            <div
              className={`flex flex-col justify-between py-3.5 grow border border-t-0 rounded-b-lg border-grey-light min-h-62 ${isFormCard ? "" : "gap-1"}`}
            >
              {sections?.map((section, sectionIndex) => (
                <div key={`plan-card-${sectionIndex}`} className="flex flex-col">
                  <div
                    className={`flex justify-between items-center gap-2 ${selectedSection === section.title || (section.subsections && section.subsections.some((subsection) => selectedSection === subsection.title)) ? "bg-blue-light-4" : ""} min-h-10 2xl:min-h-1 px-4 2xl:px-5`}
                  >
                    <div
                      className={`flex items-center min-h-9 ${isFormCard ? "min-h-8" : "2xl:min-h-12"} text-16 2xl:text-18 gap-2`}
                    >
                      <span className="text-primary font-bold min-w-7 flex-1">{`${index}.${sectionIndex + 1}`}</span>
                      <span className="text-14 leading-5 2xl:text-16 2xl:leading-normal">
                        {section.title}
                      </span>
                    </div>

                    <Button
                      disabled={!canBeEdited(section)}
                      className="min-w-12"
                      outline={isEdited(section.name) && canBeEdited(section)}
                      size="small"
                      onClick={() =>
                        handleNavigateForSection(
                          `/business_plan/ai_generator/${url}/${section.url}`,
                        )
                      }
                    >
                      {isEdited(section.name) && canBeEdited(section) ? "Edit" : "Start"}
                    </Button>
                  </div>
                  {section.subsections && isFormCard && (
                    <div className="flex flex-col">
                      {section.subsections.map((subsection, index) => (
                        <div
                          key={`subsection-${sectionIndex}-${index}`}
                          className={`flex justify-between items-center gap-2 ${selectedSection === subsection.title ? "bg-blue-light-4" : ""} px-4 2xl:px-5`}
                        >
                          <div className="flex items-center gap-2 min-h-8">
                            <div className="w-7 flex items-center justify-center shrink-0">
                              <div className="w-1.5 h-1.5 bg-primary rounded-full"></div>
                            </div>
                            <span className="leading-4 2xl:leading-5 text-14 2xl:text-16">
                              {subsection.title}
                            </span>
                          </div>

                          <Button
                            disabled={
                              subsection.requiredSection && !isEdited(subsection.requiredSection)
                            }
                            outline={isEdited(subsection.name) && canBeEdited(subsection)}
                            className="min-w-12"
                            size="small"
                            onClick={() =>
                              handleNavigateForSection(
                                `/business_plan/ai_generator/${url}/${subsection.url}`,
                              )
                            }
                          >
                            {isEdited(subsection.name) && canBeEdited(subsection)
                              ? "Edit"
                              : "Start"}
                          </Button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
}
