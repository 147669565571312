import { getIndexSvgIcon } from "../helpers/indexSvg";

export function Header(props) {
  const { section, index, page } = props;
  return (
    <div className="w-full h-fit bg-blue-light-4 flex items-center gap-9 py-2 pl-20">
      <div className="flex items-end gap-1 mb-1 h-20">
        {getIndexSvgIcon(index)}
        <div className="w-6 h-6 min-w-6 min-h-6 border-2 border-primary rounded-full"></div>
        {getIndexSvgIcon(section.index)}
      </div>
      <div className="flex flex-col">
        <span className="text-14  text-primary">
          {global.Modeliks.BusinessPlanScenarioInfo.Name}
        </span>
        <span className="text-36 font-bold">{section.title}</span>
        <span className="text-14  text-grey-dark-2">{page}</span>
      </div>
    </div>
  );
}
