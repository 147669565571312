import { useNavigate } from "react-router-dom";
import { getIndexSvgIcon } from "../helpers/indexSvg";
import { usePage } from "../contexts/PageProvider";

export function BusinessPlanCardHeader(props) {
  const navigate = useNavigate();
  const { title, index, isFormCard, indexSelected } = props;
  const { isLoading } = usePage();

  function onNavigateClick(index) {
    if (isLoading) return;
    if (index === indexSelected) return;

    if (index === 1) {
      navigate("/business_plan/ai_generator/business_overview/description");
    } else if (index === 2) {
      navigate("/business_plan/ai_generator/market_analysis/problems");
    } else {
      navigate("/business_plan/ai_generator/strategy/marketing_channels");
    }
  }

  if (isFormCard) {
    return (
      <div className="flex flex-col border border-b-0 rounded-t-lg border-grey-light">
        <div className="w-full grid grid-cols-3 relative z-10 rounded-t-lg overflow-hidden">
          <div
            onClick={() => onNavigateClick(1)}
            className={`flex justify-center cursor-pointer h-9 2xl:h-12 p-2 border-t-0 border-grey-light ${indexSelected === 1 ? "bg-blue-light-4 border-r rounded-se-lg" : "border-b"}`}
          >
            {getIndexSvgIcon(1)}
          </div>
          <div
            onClick={() => onNavigateClick(2)}
            className={`flex justify-center cursor-pointer h-9 2xl:h-12 p-2 border-t-0 border-grey-light ${indexSelected === 2 ? "bg-blue-light-4 rounded-t-lg border border-b-0" : "border-b"}`}
          >
            {getIndexSvgIcon(2)}
          </div>
          <div
            onClick={() => onNavigateClick(3)}
            className={`flex justify-center cursor-pointer h-9 2xl:h-12 p-2 border-t-0 border-grey-light ${indexSelected === 3 ? "bg-blue-light-4 rounded-t-lg border-l" : "border-b"}`}
          >
            {getIndexSvgIcon(3)}
          </div>
        </div>
        <div className="bg-blue-light-4 p-3 text-center">
          <span className="font-bold max-w-40 text-20 2xl:text-32">{title}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-blue-light-4 min-h-24 max-h-32 flex items-center gap-4 p-4 2xl:p-6 border border-b-0 rounded-t-lg border-grey-light">
      <div className="w-10 h-14 xl:w-16 2xl:w-20 2xl:h-16 shrink-0">{getIndexSvgIcon(index)}</div>
      <div className="flex items-center min-h-10 2xl:min-h-24">
        <span className="font-bold max-w-40 text-20 2xl:text-32">{title}</span>
      </div>
    </div>
  );
}
