import { useEffect, useState } from "react";
import { Button } from "../../components/CopiedFromRefactor/Button";
import { CardSelect } from "../../components/CardSelect";
import { QuestionWrapper } from "../../components/QuestionWrapper";
import { Input } from "../../components/Input";

import { usePage } from "../../contexts/PageProvider";
import { SectionMode } from "../../helpers/enums";
import { useSave } from "../../hooks/useSave";
import {
  dtoOptionsToAiGeneratedContent,
  marketProblemsAndSolutionsObjectToAiGeneratedContentDto,
} from "../../models/aiGeneratedContent/transformer";
import { fetchOptions } from "../../Services/api/Repositories/BusinessPlanGenerator";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";

import { iconOneToOne } from "../../icons";
import { FormNavigation } from "../../components/FormNavigation";
import { PromptWrapper } from "../../components/PromptWrapper";

export function MarketSolutions() {
  const {
    section,
    marketProblemsAndSolutions,
    setMarketProblemsAndSolutions,
    getGeneratedData,
    setLoading,
    isLoading,
    options,
    setOptions,
  } = usePage();

  const [sectionName, setSectionName] = useState({ sectionName: "" });
  const { nextSection, generateSection, saveSectionInputs } = useSave(sectionName);
  const [solutionCards, setSolutionCards] = useState([]);
  const [solutionInputs, setSolutionInputs] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inputError, setInputError] = useState(false);
  const [isInputActive, setIsInputActive] = useState(false);
  const [loadingSolutions, setLoadingSolutions] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (section) {
      setSectionName({ sectionName: section.name });
    }
  }, [section]);

  const bpScenarioId = global.Modeliks.BusinessPlanScenarioInfo.ID;

  function transformToSelectable(items = []) {
    const allItems = items.map((item, index) => {
      return {
        problem: item.problem,
        solution: item.solution,
        isSelected: true,
      };
    });

    return allItems;
  }

  async function saveSolutions() {
    await setMarketProblemsAndSolutions({
      problemsAndSolutions: solutionInputs,
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await saveSectionInputs(
            marketProblemsAndSolutionsObjectToAiGeneratedContentDto(generatedData, sectionName),
          );

          await fetchSolutions(generatedData);
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });
  }

  async function fetchSolutions(data) {
    try {
      setLoadingSolutions(true);
      const solutionResponse = await fetchOptions({
        bpScenarioId,
        optionKey: "solutions",
      });

      const solutions = dtoOptionsToAiGeneratedContent(solutionResponse.SectionData.Options);

      let generatedSolutions = [];

      if (data) {
        generatedSolutions = data.marketProblemsAndSolutions.problemsAndSolutions.map(
          (generatedSolution) => {
            if (generatedSolution.solution === null) {
              const matchingSolution = solutions.problemsAndSolutions.find((solution) => {
                return solution.problem === generatedSolution.problem;
              });
              return {
                ...generatedSolution,
                solution: matchingSolution ? matchingSolution.solution : null,
              };
            }
            return generatedSolution;
          },
        );
      } else {
        generatedSolutions = marketProblemsAndSolutions.problemsAndSolutions.map(
          (generatedSolution) => {
            if (generatedSolution.solution === null) {
              const matchingSolution = solutions.problemsAndSolutions.find((solution) => {
                return solution.problem === generatedSolution.problem;
              });
              return {
                ...generatedSolution,
                solution: matchingSolution ? matchingSolution.solution : null,
              };
            }
            return generatedSolution;
          },
        );
      }

      setSolutionInputs(generatedSolutions);
      setSolutionCards(transformToSelectable(generatedSolutions));
    } catch (error) {
      console.error("Error fetching solutions:", error);
    } finally {
      setLoadingSolutions(false);
    }
  }

  useEffect(() => {
    if (isLoading) return;
    if (marketProblemsAndSolutions && isInitialLoad) {
      setIsInitialLoad(false);
      if (marketProblemsAndSolutions.problemsAndSolutions.some((item) => item.solution === null)) {
        fetchSolutions();
      } else {
        setSolutionInputs(marketProblemsAndSolutions.problemsAndSolutions);
        setSolutionCards(transformToSelectable(marketProblemsAndSolutions.problemsAndSolutions));
      }
    }
  }, [marketProblemsAndSolutions]);

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  function onCardClick(problem) {
    setError(false);
    setSolutionCards((prevCards) =>
      prevCards.map((card, i) =>
        card.problem === problem
          ? {
              ...card,
              isSelected: !card.isSelected,
            }
          : card,
      ),
    );

    setSolutionInputs((prevSolutions) =>
      prevSolutions.map((card, i) =>
        card.problem === problem
          ? {
              ...card,
              solution:
                card.solution === null
                  ? solutionCards.find((card) => card.problem === problem).solution
                  : null,
            }
          : card,
      ),
    );
  }

  async function handleSaveEdit(problem, newTitle) {
    setSolutionCards((prevCards) =>
      prevCards.map((card) => (card.problem === problem ? { ...card, solution: newTitle } : card)),
    );

    setSolutionInputs((prevInputs) =>
      prevInputs.map((card) => (card.problem === problem ? { ...card, solution: newTitle } : card)),
    );
  }

  function validate() {
    if (!solutionCards.every((card) => card.isSelected)) {
      setError(true);
    }
    return solutionCards.every((card) => card.isSelected);
  }

  async function onNextHandle() {
    if (!validate()) return "market_analysis/target_market";

    await setMarketProblemsAndSolutions({
      problemsAndSolutions: solutionInputs,
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await nextSection(
            marketProblemsAndSolutionsObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });
    return "market_analysis/target_market";
  }

  async function onGenerateHandle() {
    if (!validate()) return;

    await setMarketProblemsAndSolutions({
      problemsAndSolutions: solutionInputs,
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await generateSection(
            marketProblemsAndSolutionsObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });

    return sectionName;
  }

  async function onSave() {
    await setMarketProblemsAndSolutions({
      problemsAndSolutions: solutionInputs,
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await saveSectionInputs(
            marketProblemsAndSolutionsObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });

    return sectionName;
  }

  return (
    <>
      <PromptWrapper>
        <QuestionWrapper
          isAnswered={solutionCards.some((card) => card.isSelected)}
          question={"How does your business solve these problems?"}
          description={
            "Explain how your business solves each problem and the benefits that customers get when using your product or service."
          }
          error={error}
          onRegenerateOptions={saveSolutions}
        >
          {loadingSolutions ? (
            <CircleLoader />
          ) : (
            <>
              <div className="grid grid-cols-1 gap-3">
                {solutionCards?.map((card, index) => {
                  const isFirstRow = index === 0;

                  return (
                    <div
                      className="grid grid-cols-7 gap-2 items-center justify-items-center"
                      key={`problem-solution-${index}`}
                    >
                      <div className="flex flex-col gap-1 col-span-3 relative h-full w-full">
                        {isFirstRow && (
                          <span className="font-bold text-18 absolute -top-7 left-0">Problems</span>
                        )}
                        <CardSelect value={card.problem} key={`problems-card-${index}`} nonEditable>
                          {card.problem}
                        </CardSelect>
                      </div>
                      {iconOneToOne}

                      <div className="flex flex-col gap-1 col-span-3 relative h-full w-full">
                        {isFirstRow && (
                          <span className="font-bold text-18 absolute -top-7 left-0">
                            Solutions
                          </span>
                        )}
                        <CardSelect
                          value={card.solution}
                          onClick={() => onCardClick(card.problem)}
                          key={`select-card-${index}`}
                          isSelected={card.isSelected}
                          onSaveEdit={(i, newValue) => handleSaveEdit(card.problem, newValue)}
                          index={index}
                        >
                          {card.solution}
                        </CardSelect>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </QuestionWrapper>
      </PromptWrapper>
      <FormNavigation
        onNext={onNextHandle}
        onGenerate={onGenerateHandle}
        onSave={onSave}
        initialState={marketProblemsAndSolutions}
        newState={{ problemsAndSolutions: solutionInputs }}
      />
    </>
  );
}
