import { useContext, useEffect } from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";

export function useBlocker(blocker, when = true) {
  const { navigator } = useContext(UNSAFE_NavigationContext);

  useEffect(() => {
    if (!when) return;

    const push = navigator.push;
    const replace = navigator.replace;

    navigator.push = (...args) => {
      const [path, state] = args;
      const canNavigate = blocker({ pathname: path, state });
      if (canNavigate) {
        push.apply(navigator, args);
      }
    };

    navigator.replace = (...args) => {
      const [path, state] = args;
      const canNavigate = blocker({ pathname: path, state });
      if (canNavigate) {
        replace.apply(navigator, args);
      }
    };

    return () => {
      navigator.push = push;
      navigator.replace = replace;
    };
  }, [navigator, blocker, when]);
}
