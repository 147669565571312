import { useState, useEffect } from "react";
import { Button } from "../../components/CopiedFromRefactor/Button";
import { Input } from "../../components/Input";
import { QuestionWrapper } from "../../components/QuestionWrapper";
import { usePage } from "../../contexts/PageProvider";
import { useSave } from "../../hooks/useSave";
import { SectionMode } from "../../helpers/enums";
import { businessIntellectualPropertyObjectToAiGeneratedContentDto } from "../../models/aiGeneratedContent/transformer";

import { CardSelect } from "../../components/CardSelect";
import { FormNavigation } from "../../components/FormNavigation";
import { PromptWrapper } from "../../components/PromptWrapper";

const initialIPState = {
  name: "",
  description: "",
  acquiredMonth: "",
  acquiredYear: "",
  expiryMonth: "",
  expiryYear: "",
  countries: "",
  registrationNumber: "",
  typeIntellectualProperty: null,
  errors: {},
};

const initialCards = ["Patent", "Trademark", "Copyright", "Trade Secret", "Franchise"];

function isAllFieldsFilled(ip) {
  return (
    ip.name.trim() &&
    ip.description.trim() &&
    ip.acquiredMonth.trim() &&
    ip.acquiredYear.trim() &&
    ip.expiryMonth.trim() &&
    ip.expiryYear.trim() &&
    ip.countries.trim() &&
    ip.registrationNumber.trim() &&
    ip.typeIntellectualProperty !== null
  );
}

export function BusinessIntelectualProperty() {
  const {
    section,
    businessIntellectualProperty,
    setBusinessIntellectualProperty,
    getGeneratedData,
    setLoading,
  } = usePage();

  const [sectionName, setSectionName] = useState({ sectionName: "" });
  const { nextSection, generateSection, saveSectionInputs } = useSave(sectionName);

  const [ipList, setIpList] = useState([initialIPState]);
  const [cards, setCards] = useState(initialCards);

  useEffect(() => {
    if (section) {
      setSectionName({ sectionName: section.name });
    }
  }, [section]);

  useEffect(() => {
    if (businessIntellectualProperty?.intellectualProperties) {
      setIpList(
        businessIntellectualProperty.intellectualProperties.map((ip) => ({
          ...initialIPState,
          name: ip.nameIntellectualProperty || "",
          description: ip.descriptionIntellectualProperty || "",
          acquiredMonth: ip.registrationDateIntellectualProperty?.split(" ")[0] || "",
          acquiredYear: ip.registrationDateIntellectualProperty?.split(" ")[1] || "",
          expiryMonth: ip.expiryDateIntellectualProperty?.split(" ")[0] || "",
          expiryYear: ip.expiryDateIntellectualProperty?.split(" ")[1] || "",
          countries: ip.registeredCountriesIntellectualProperty || "",
          registrationNumber: ip.registrationNumberIntellectualProperty || "",
          typeIntellectualProperty: ip.typeIntellectualProperty || null,
          errors: {},
        })),
      );
    }
  }, [businessIntellectualProperty]);

  useEffect(() => {
    if (businessIntellectualProperty?.intellectualProperties) {
      const newCards = ipList
        .map((ip) => ip.typeIntellectualProperty)
        .filter((ip) => ip && !cards.includes(ip));

      if (newCards.length > 0) {
        setCards((prevCards) => [...prevCards, ...newCards]);
      }
    }
  }, [ipList]);

  function handleInputChange(index, field, value) {
    setIpList((prevList) =>
      prevList.map((ip, i) => {
        if (i === index) {
          const updatedIP = { ...ip, [field]: value };
          if (ip.errors[field]) {
            delete updatedIP.errors[field];
          }
          return updatedIP;
        }
        return ip;
      }),
    );
  }

  function handleCardSelect(index, selectedType) {
    setIpList((prevList) =>
      prevList.map((ip, i) =>
        i === index
          ? {
              ...ip,
              typeIntellectualProperty:
                ip.typeIntellectualProperty === selectedType ? null : selectedType,
            }
          : ip,
      ),
    );
  }

  function addNewIP() {
    setIpList([...ipList, initialIPState]);
  }

  function removeIP(index) {
    setIpList((prevList) => prevList.filter((_, i) => i !== index));
  }

  function validate(shouldReturnErrors) {
    let allValid = true;
    const updatedIPList = ipList.map((ip) => {
      const newErrors = {};
      Object.keys(initialIPState).forEach((key) => {
        if (key !== "errors" && !ip[key]?.trim()) {
          newErrors[key] = "This field is required";
          allValid = false;
        }
      });
      return shouldReturnErrors ? { ...ip, errors: newErrors } : ip;
    });

    if (shouldReturnErrors) {
      setIpList(updatedIPList);
    }

    return allValid;
  }

  const data = ipList.map((ip) => ({
    nameIntellectualProperty: ip.name,
    descriptionIntellectualProperty: ip.description,
    registrationDateIntellectualProperty:
      !!ip.acquiredMonth && !!ip.acquiredYear ? `${ip.acquiredMonth} ${ip.acquiredYear}` : "",
    expiryDateIntellectualProperty:
      !!ip.expiryMonth && !!ip.expiryYear ? `${ip.expiryMonth} ${ip.expiryYear}` : "",
    registeredCountriesIntellectualProperty: ip.countries,
    registrationNumberIntellectualProperty: ip.registrationNumber,
    typeIntellectualProperty: ip.typeIntellectualProperty,
  }));

  function handleSaveEdit(index, newValue) {
    setCards((prev) => prev.map((item, i) => (i === index ? newValue : item)));
  }

  async function onNextHandle() {
    await setBusinessIntellectualProperty({
      intellectualProperties: data.some((card) =>
        Object.values(card).some((value) => Boolean(value)),
      )
        ? data
        : undefined,
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          if (validate(false)) {
            await nextSection(
              businessIntellectualPropertyObjectToAiGeneratedContentDto(generatedData, sectionName),
            );
          } else {
            await saveSectionInputs(
              businessIntellectualPropertyObjectToAiGeneratedContentDto(generatedData, sectionName),
            );
          }
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });

    return "market_analysis/problems";
  }

  async function onGenerateHandle() {
    if (!validate(true)) return;

    await setBusinessIntellectualProperty({
      intellectualProperties: data,
      mode: SectionMode.Edit,
    });
    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await generateSection(
            businessIntellectualPropertyObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });

    return sectionName;
  }

  async function onSave() {
    await setBusinessIntellectualProperty({
      intellectualProperties: data,
      mode: SectionMode.Edit,
    });
    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await saveSectionInputs(
            businessIntellectualPropertyObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });
  }

  return (
    <>
      <PromptWrapper>
        {ipList.map((ip, index) => (
          <QuestionWrapper
            key={index}
            isAnswered={isAllFieldsFilled(ip)}
            isOptional={index === 0}
            question={
              index === 0 && `What intellectual property does the business own or have access to?`
            }
            description={
              index === 0 &&
              "Intellectual property such as patents, copyrights, trademarks, franchise, trade secrets, digital assets. If this does not apply to your business you can skip this section."
            }
          >
            <span className="text-primary text-24 font-bold">
              Intellectual Property {index + 1}
            </span>
            <div className="grid grid-cols-5 gap-3 auto-rows-fr">
              {cards.map((card, i) => (
                <CardSelect
                  key={`type-select-${i}`}
                  onClick={() => handleCardSelect(index, card)}
                  isSelected={ip.typeIntellectualProperty === card}
                  onSaveEdit={(i, newValue) => handleSaveEdit(i, newValue)}
                  index={i}
                  value={card}
                >
                  {card}
                </CardSelect>
              ))}
            </div>
            <Input
              placeholder="IP Name"
              title="How will you name this intellectual property in your business plan?"
              value={ip.name}
              error={ip.errors.name}
              onChange={(e) => handleInputChange(index, "name", e.target.value)}
            />
            <Input
              placeholder="IP Description"
              title="Description"
              value={ip.description}
              error={ip.errors.description}
              className="min-h-16"
              onChange={(e) => handleInputChange(index, "description", e.target.value)}
            />
            <div className="flex flex-col gap-2">
              <span className="text-18 font-bold">
                When was this intellectual property acquired?
              </span>
              <div className="grid grid-cols-2 gap-3">
                <Input
                  placeholder="Month"
                  value={ip.acquiredMonth}
                  error={ip.errors.acquiredMonth}
                  onChange={(e) => handleInputChange(index, "acquiredMonth", e.target.value)}
                />
                <Input
                  placeholder="Year"
                  value={ip.acquiredYear}
                  error={ip.errors.acquiredYear}
                  type="number"
                  onChange={(e) => handleInputChange(index, "acquiredYear", e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <span className="text-18 font-bold">When does it expire?</span>
              <div className="grid grid-cols-2 gap-3">
                <Input
                  placeholder="Month"
                  value={ip.expiryMonth}
                  error={ip.errors.expiryMonth}
                  onChange={(e) => handleInputChange(index, "expiryMonth", e.target.value)}
                />
                <Input
                  placeholder="Year"
                  value={ip.expiryYear}
                  error={ip.errors.expiryYear}
                  onChange={(e) => handleInputChange(index, "expiryYear", e.target.value)}
                />
              </div>
            </div>
            <Input
              placeholder="IP Countries"
              title="In which countries is it registered?"
              value={ip.countries}
              error={ip.errors.countries}
              onChange={(e) => handleInputChange(index, "countries", e.target.value)}
            />
            <Input
              placeholder="IP Registration number"
              title="What is the registration number?"
              value={ip.registrationNumber}
              error={ip.errors.registrationNumber}
              onChange={(e) => handleInputChange(index, "registrationNumber", e.target.value)}
            />

            {ipList.length > 1 && (
              <Button
                rounded
                variant="secondaryWarning"
                className="ml-auto my-4"
                onClick={() => removeIP(index)}
              >
                Remove
              </Button>
            )}
          </QuestionWrapper>
        ))}

        <Button rounded outline className="w-38" onClick={addNewIP}>
          Add another IP
        </Button>
      </PromptWrapper>
      <FormNavigation
        disableGenerateBtn={!validate(false)}
        onGenerate={onGenerateHandle}
        onNext={onNextHandle}
        onSave={onSave}
        initialState={businessIntellectualProperty}
        newState={{
          intellectualProperties: data.some((card) =>
            Object.values(card).some((value) => Boolean(value)),
          )
            ? data
            : undefined,
        }}
      />
    </>
  );
}
