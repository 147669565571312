import { useState, useEffect } from "react";
import { QuestionWrapper } from "../../components/QuestionWrapper";
import { CardSelect } from "../../components/CardSelect";
import { Button } from "../../components/CopiedFromRefactor/Button";
import { Input } from "../../components/Input";
import { usePage } from "../../contexts/PageProvider";
import { useSave } from "../../hooks/useSave";
import { SectionMode } from "../../helpers/enums";
import { businessOwnershipObjectToAiGeneratedContentDto } from "../../models/aiGeneratedContent/transformer";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import { iconClose } from "../../icons";
import { FormNavigation } from "../../components/FormNavigation";
import { PromptWrapper } from "../../components/PromptWrapper";

const initialCards = [
  "Sole Proprietorship",
  "Limited Liability Company (LLC)",
  "Partnership",
  "Corporation",
  "Non Profit",
];

export function BusinessOwnership() {
  const {
    section,
    businessOwnership,
    setBusinessOwnership,
    getGeneratedData,
    setLoading,
    hasFetchedData,
  } = usePage();

  const [sectionName, setSectionName] = useState({ sectionName: "" });
  const { nextSection, generateSection, saveSectionInputs } = useSave(sectionName);

  const [cards, setCards] = useState(initialCards);
  const [inputValue, setInputValue] = useState("");
  const [selectedCardValue, setSelectedCardValue] = useState(null);
  const [isInputActive, setIsInputActive] = useState(false);
  const [inputError, setInputError] = useState(null);
  const [owners, setOwners] = useState([{ name: "", percentage: "" }]);
  const [loadingData, setIsLoadingData] = useState(true);

  const [ownersError, setOwnersError] = useState(false);
  const [strucutreError, setStructureError] = useState(false);

  useEffect(() => {
    if (section) {
      setSectionName({ sectionName: section.name });
    }
  }, [section]);

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  function addCard() {
    const trimmedInput = inputValue.trim();
    if (cards.includes(trimmedInput) || trimmedInput === selectedCardValue) {
      setInputError(true);
      return;
    }
    if (trimmedInput) {
      setCards((prevCards) => [...prevCards, trimmedInput]);
      setInputValue("");
      setIsInputActive(false);
    }
  }

  useEffect(() => {
    try {
      if (businessOwnership) {
        setOwners(businessOwnership.shareholders || [{ name: "", percentage: "" }]);

        if (
          businessOwnership.companyStructureType &&
          !cards.includes(businessOwnership.companyStructureType)
        ) {
          setCards((prevCards) => [...prevCards, businessOwnership.companyStructureType]);
        }
        setSelectedCardValue(businessOwnership.companyStructureType || null);
      }
    } finally {
      setIsLoadingData(false);
    }
    if (hasFetchedData) setIsLoadingData(false);
  }, [businessOwnership, hasFetchedData]);

  function onAddCard() {
    setInputError(false);

    addCard();
  }

  function onCardClick(value) {
    setStructureError(false);
    if (value === selectedCardValue) {
      setSelectedCardValue(null);
    } else {
      setSelectedCardValue(value);
    }
  }

  function addNewOwner() {
    setOwners([...owners, { name: "", percentage: "" }]);
  }

  function changeOwnerName(index, event) {
    setOwnersError(false);
    const newOwners = [...owners];
    newOwners[index].name = event.target.value;
    setOwners(newOwners);
  }

  function changeOwnerOwnership(index, event) {
    setOwnersError(false);
    const newOwners = [...owners];
    if (event.target.value >= 100) {
      newOwners[index].percentage = 100;
    } else {
      newOwners[index].percentage = event.target.value;
    }

    setOwners(newOwners);
  }

  function handleSaveEdit(index, value) {
    setCards((prevCards) => prevCards.map((card, i) => (i === index ? value : card)));
  }

  function removeOwner(index) {
    setOwners(owners.filter((_, ownerIndex) => ownerIndex !== index));
  }

  function areAllOwnersFilled() {
    return owners.every((owner) => owner.name.trim() && owner.percentage);
  }

  function validate() {
    if (!areAllOwnersFilled()) {
      setOwnersError(true);
    }
    if (!selectedCardValue) {
      setStructureError(true);
    }
    return areAllOwnersFilled() && selectedCardValue;
  }

  async function onNextHandle() {
    await setBusinessOwnership({
      shareholders: owners,
      companyStructureType: selectedCardValue,
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          if (areAllOwnersFilled() && selectedCardValue) {
            await nextSection(
              businessOwnershipObjectToAiGeneratedContentDto(generatedData, sectionName),
            );
          } else {
            await saveSectionInputs(
              businessOwnershipObjectToAiGeneratedContentDto(generatedData, sectionName),
            );
          }
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });

    return "business_overview/products_and_services";
  }

  async function onGenerateHandle() {
    if (!validate()) return;

    await setBusinessOwnership({
      shareholders: owners,
      companyStructureType: selectedCardValue,
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await generateSection(
            businessOwnershipObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });

    return sectionName;
  }

  async function onSave() {
    await setBusinessOwnership({
      shareholders: owners,
      companyStructureType: selectedCardValue,
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await saveSectionInputs(
            businessOwnershipObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });
  }

  return (
    <>
      <PromptWrapper>
        {loadingData ? (
          <CircleLoader />
        ) : (
          <>
            <QuestionWrapper
              error={strucutreError}
              isAnswered={selectedCardValue}
              question={"How is the business be structured legally?"}
              description={
                "Select one of the following common business structures or add your own."
              }
              maxSelectiveOptions={1}
            >
              <div className="grid grid-cols-5 gap-3 auto-rows-fr">
                {cards.map((card, index) => {
                  return (
                    <CardSelect
                      onClick={() => onCardClick(card)}
                      key={`select-card-${index}`}
                      isSelected={card === selectedCardValue}
                      value={card}
                      index={index}
                      onSaveEdit={(i, newValue) => handleSaveEdit(i, newValue)}
                    >
                      {card}
                    </CardSelect>
                  );
                })}
                {isInputActive && (
                  <Input
                    error={inputError}
                    placeholder="Business Value"
                    className="text-center"
                    value={inputValue}
                    onChange={handleInputChange}
                    onAddCard={onAddCard}
                  />
                )}
              </div>

              <Button className={"w-38"} rounded outline onClick={() => setIsInputActive(true)}>
                Add More
              </Button>
            </QuestionWrapper>

            <QuestionWrapper
              error={ownersError}
              isAnswered={areAllOwnersFilled()}
              question={"Who are the business owners?"}
              description={
                "List the names of the owners and their percent ownership in the company."
              }
            >
              {loadingData ? (
                <CircleLoader />
              ) : (
                <>
                  {owners.map((owner, index) => {
                    return (
                      <div key={`owner-${index}`} className="grid grid-cols-4 gap-3 relative pr-4">
                        <div className="col-span-3">
                          <Input
                            placeholder="Shareholder name"
                            value={owner.name}
                            onChange={(event) => changeOwnerName(index, event)}
                          />
                        </div>
                        <Input
                          className={`${owner.percentage ? "text-right" : ""}`}
                          placeholder="Percent ownership"
                          type="number"
                          value={owner.percentage}
                          onChange={(event) => changeOwnerOwnership(index, event)}
                        />
                        {owners.length > 1 && (
                          <div
                            className="absolute top-1 right-0 cursor-pointer"
                            onClick={() => removeOwner(index)}
                          >
                            {iconClose}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
              <Button className={"w-38"} rounded outline onClick={addNewOwner}>
                Add More
              </Button>
            </QuestionWrapper>
          </>
        )}
      </PromptWrapper>
      <FormNavigation
        onNext={onNextHandle}
        onGenerate={onGenerateHandle}
        onSave={onSave}
        initialState={businessOwnership}
        newState={{
          shareholders:
            owners.filter((card) => card.name && card.percentage).length > 0 ? owners : undefined,
          companyStructureType: selectedCardValue ? selectedCardValue : undefined,
        }}
      />
    </>
  );
}
