import { useState, useEffect } from "react";
import { QuestionWrapper } from "../../components/QuestionWrapper";
import { CardSelect } from "../../components/CardSelect";
import { Button } from "../../components/CopiedFromRefactor/Button";
import { Input } from "../../components/Input";
import { usePage } from "../../contexts/PageProvider";
import { useSave } from "../../hooks/useSave";
import { businessValuesObjectToAiGeneratedContentDto } from "../../models/aiGeneratedContent/transformer";
import { SectionMode } from "../../helpers/enums";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import { FormNavigation } from "../../components/FormNavigation";
import { PromptWrapper } from "../../components/PromptWrapper";

const initialCards = [
  "Integrity",
  "Innovation",
  "Customer centric",
  "Quality",
  "Transparency",
  "Accountability",
  "Teamwork",
  "Sustainability",
  "Diversity and Inclusion",
  "Reliability",
  "Continuous improvement",
  "Trust",
  "Efficiency",
  "Social responsibility",
  "Respect",
  "Passion",
  "Value for money",
  "Result oriented",
  "Detail oriented",
  "Nothing is impossible",
];

const transformedInitialCards = initialCards.map((title, index) => ({
  title: title,
  value: index,
}));

export function BusinessValues() {
  const {
    section,
    businessValues,
    setBusinessValues,
    getGeneratedData,
    setLoading,
    hasFetchedData,
  } = usePage();

  const [sectionName, setSectionName] = useState({ sectionName: "" });
  const { nextSection, generateSection, saveSectionInputs } = useSave(sectionName);
  const [cards, setCards] = useState(transformedInitialCards);
  const [inputValue, setInputValue] = useState("");
  const [data, setData] = useState([]);
  const [isInputActive, setIsInputActive] = useState(false);
  const [error, setError] = useState(false);
  const [loadingValues, setLoadingValues] = useState(true);

  useEffect(() => {
    if (section) {
      setSectionName({ sectionName: section.name });
    }
  }, [section]);

  useEffect(() => {
    if (businessValues && businessValues.companyValues) {
      setLoadingValues(false);
      const preselectedCards = businessValues.companyValues.map((title, index) => {
        const existingCard = transformedInitialCards.find((card) => card.title === title);
        if (existingCard) {
          return { ...existingCard };
        }
        return { title, value: transformedInitialCards.length + index };
      });

      setData(preselectedCards);

      const updatedCards = [
        ...transformedInitialCards,
        ...preselectedCards.filter(
          (card) =>
            !transformedInitialCards.some((initialCard) => initialCard.title === card.title),
        ),
      ];

      setCards(updatedCards);
    }
    if (hasFetchedData) setLoadingValues(false);
  }, [businessValues]);

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  function addCard() {
    if (inputValue && inputValue.trim()) {
      const newCard = { title: inputValue.trim(), value: cards.length };
      setCards((prevCards) => [...prevCards, newCard]);
      setInputValue("");
      setIsInputActive(false);
    }
  }

  function isCardSelected(value) {
    return data.some((card) => card.value === value);
  }

  function onCardClick(value) {
    setError(false);
    const cardSelected = isCardSelected(value);
    if (cardSelected) {
      setData((prevData) => prevData.filter((card) => card.value !== value));
    } else {
      const selectedCard = cards.find((card) => card.value === value);
      setData((prevData) => [...prevData, selectedCard]);
    }
  }

  function validate() {
    if (data.length > 0) {
      return true;
    }
    setError(true);
    return false;
  }

  function handleSaveEdit(index, newTitle) {
    setCards((prevCards) =>
      prevCards.map((card, i) => (i === index ? { ...card, title: newTitle } : card)),
    );
  }

  function updateBusinessValues(newData) {
    return new Promise((resolve) => {
      setBusinessValues(newData);
      resolve();
    });
  }

  async function onNextHandle() {
    updateBusinessValues({
      companyValues: data.length > 0 ? data.map((card) => card.title) : null,
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await nextSection(
            businessValuesObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });
    return "business_overview/ownership";
  }

  async function onGenerateHandle() {
    if (!validate()) return;

    await updateBusinessValues({
      companyValues: data.length > 0 ? data.map((card) => card.title) : null,
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await generateSection(
            businessValuesObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });

    return sectionName;
  }

  async function onSave() {
    await updateBusinessValues({
      companyValues: data.length > 0 ? data.map((card) => card.title) : null,
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await saveSectionInputs(
            businessValuesObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });
  }

  return (
    <>
      <PromptWrapper>
        {loadingValues ? (
          <CircleLoader />
        ) : (
          <QuestionWrapper
            error={error}
            isAnswered={!!data.length}
            question={"What are your business values?"}
            description={
              "Business values are the fundamental beliefs and guiding principles that shape a company’s culture, decision-making, and overall behavior."
            }
            hasSelectOptions
          >
            <div className="grid grid-cols-4 xl:grid-cols-5 gap-3 auto-rows-fr">
              {cards.map((card, index) => (
                <CardSelect
                  onClick={() => onCardClick(card.value)}
                  index={index}
                  value={card.title}
                  key={`select-card-${index}`}
                  isSelected={isCardSelected(card.value)}
                  onSaveEdit={handleSaveEdit}
                >
                  {card.title}
                </CardSelect>
              ))}
              {isInputActive && (
                <Input
                  placeholder="Business Value"
                  className="text-center"
                  value={inputValue}
                  onChange={handleInputChange}
                  onAddCard={addCard}
                />
              )}
            </div>

            <Button className={"w-38"} rounded outline onClick={() => setIsInputActive(true)}>
              Add More
            </Button>
          </QuestionWrapper>
        )}
      </PromptWrapper>

      <FormNavigation
        onNext={onNextHandle}
        onGenerate={onGenerateHandle}
        onSave={onSave}
        initialState={businessValues}
        newState={{
          companyValues: data.length > 0 ? data.map((card) => card.title) : undefined,
        }}
      />
    </>
  );
}
